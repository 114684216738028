/* .chenco-holdings,
.fitted.tv,
.project-row:first-child,
.project-row:last-child {
  display: none;
} */

.chenco-holdings {
  background-color: rgba(37, 79, 123, 0.8);
}

. .control-forever {
  background-color: rgba(237, 162, 41, 0.8);
}
.control-forever.project p,
.control-forever.project h2 {
  /* color: #fff !important; */
}

.control-forever.project .ui.inverted.button {
  /* color: #000;
  border: 2px solid #000;
  box-shadow: none !important; */
}

.control-forever.project .ui.inverted.button:hover {
  /* color: #fff;
  background: #000; */
  /* border-color: #fff; */
}

.global-road-entertainment {
  background-color: rgba(128, 0, 0, 0.8);
}

.replace-hate {
  /* background-color: rgba(218, 218, 218, 0.8); */
  background-color: rgba(225, 41, 48, 0.8);
}

.fox-innovation-labs {
  background-color: rgba(43, 54, 122, 0.8);
}

.global-road-entertainment-splash {
  background-color: rgba(28, 33, 44, 0.8);
}

.fox-movies {
  background-color: rgba(81, 26, 34, 0.8);
}

.bleed-for-this {
  background-color: rgba(215, 192, 102, 0.8);
}

.sugarsync {
  background-color: rgba(97, 154, 106, 0.8);
}

.takethecure {
  background-color: rgba(93, 91, 72, 0.8);
}

.lucidworks {
  background-color: rgba(46, 71, 94, 0.8);
}

.universal-talent-development {
  background-color: rgba(49, 40, 28, 0.8);
}

.la-philharmonic {
  background-color: rgba(86, 97, 105, 0.8);
}

.hollywood-bowl {
  background-color: rgba(44, 58, 120, 0.8);
}

.solr {
  background-color: rgba(186, 84, 56, 0.8);
}

.lucidworks {
  background-color: rgba(46, 71, 94, 0.8);
}

.universal-portal {
  background-color: rgba(69, 118, 194, 0.8);
}

.kingsman-exclusive-screenings {
  background-color: rgba(0, 0, 0, 0.8);
}

.fox-holiday-card {
  background-color: rgba(222, 222, 222, 0.8);
}

.wespot {
  background-color: rgba(0, 0, 0, 0.8);
}

.project-row {
  display: flex;
}

.project {
  background-repeat: repeat;
  background-image: url('../../images/3px-tile.png');
  /* flex: 0.5; */
  flex: 1;
}
.project:nth-child(2n + 2) {
}

.project .ui.image {
  margin: 0 auto;
  padding: 4em 4em 0 4em;
  max-width: 1400px;
  width: 100%;
}
.project p {
  color: #fff;
}
.project-text {
  padding: 4em 0;
}

.project__tags {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.project__tag {
  padding: 0 1em;
  border-right: 1px solid #fff;
  color: #fff;
}
.project__tag:last-child {
  border-right: none;
}

@media only screen and (max-width: 768px) {
  .project {
    padding-bottom: 0;
    height: auto;
    width: 100%;
  }
  .project-text {
    padding: 2em 0;
  }
  .project .ui.image {
    padding: 2em 2em 0 2em;
  }
}

.text {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  font-size: 1.2em;
  color: #000;
}
